import React from "react";
import { Element } from "react-scroll";
import imgOne from "../assets/imgOne.svg";
//import Fade from "react-reveal/Fade";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#CB7A94",
  },

  icon: {
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "6rem",
    paddingBottom: "4rem",
    alignItems: "center",
  },
  image: {
    width: "90%",
  },
  text: {
    color: "#FFECEF",
  },
  buttonMore: {
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none",
    color: "#84505B",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "1rem",
  },
  wrapperSmall: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  },
  imageWrapperSmall: {
    padding: "1.5rem",
  },
});

const Intro = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Element id="intro">
      <div className={classes.root}>
        {isSmall ? (
          <Grid>
            <Grid className={classes.imageWrapperSmall} item xs={12}>
              <img className={classes.image} src={imgOne} alt="imgOne" />
            </Grid>
            <Grid item xs={12} className={classes.wrapperSmall}>
                <Typography
                  className={classes.text}
                  color="primary.light"
                  variant="h4"
                >
                  A plataforma que educa mulheres a empreender sem sair de casa
                </Typography>
                <Typography
                  className={classes.text}
                  color="primary.light"
                  variant="h3"
                  style={{ fontFamily: "Abril Fatface" }}
                >
                  #GIRLPOWER
                </Typography>
                <Button variant="contained" className={classes.buttonMore}>
                  Saiba mais sobre a giden
                </Button>
            </Grid>
          </Grid>
        ) : (
          <Container>
            <Grid container className={classes.wrapper}>
              <Grid item sm={6}>
                  <img className={classes.image} src={imgOne} alt="imgOne" />
              </Grid>
              <Grid item sm={6}>
                  <Typography
                    className={classes.text}
                    color="primary.light"
                    variant="h4"
                  >
                    A plataforma que educa mulheres a empreender sem sair de
                    casa
                  </Typography>
                  <Typography
                    className={classes.text}
                    color="primary.light"
                    variant="h3"
                    style={{ fontFamily: "Abril Fatface" }}
                  >
                    #GIRLPOWER
                  </Typography>
                  <Button variant="contained" className={classes.buttonMore}>
                    Faça parte
                  </Button>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Element>
  );
};

export default Intro;
