import React from "react";
import { Element } from "react-scroll";
//import Roll from "react-reveal/Roll";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ECAFBA",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: "5rem",
    color: "#FEECEF",
    fontWeight: "normal",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "5rem",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "8rem",
    width: "8rem",
    padding: "1rem",
    borderRadius: "100%",
    margin: "1rem",
    fontWeight: "bold",
  },
});

const ComoFunciona = () => {
  const classes = useStyles();

  return (
    <Element id="como-funciona">
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={12} className={classes.titleWrapper}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#84505B",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  Como Funciona
                </Typography>
            </Grid>
            <Grid container xs={12} className={classes.wrapper}>
                <Box
                  className={classes.box}
                  style={{ backgroundColor: "#FEECEF", color: "#84505B" }}
                >
                  Responda o quiz da Gia sobre você e seu negócio
                </Box>
                <Box
                  className={classes.box}
                  style={{ backgroundColor: "#CB7A94" }}
                >
                  Descubra em qual nível seu perfil se encaixa
                </Box>
                <Box
                  className={classes.box}
                  style={{ backgroundColor: "#84505B" }}
                >
                  Inicie sua jornada empreendedora com ajuda da Gia
                </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default ComoFunciona;
