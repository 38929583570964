import React, { useState } from "react";
import { Link } from "react-scroll";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  listItems: {
    color: "#A0A4A8",
    "&.Mui-selected": {
      color: "#CB7A94",
      backgroundColor: "transparent",
    },
    "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
  },
  group: {
    justifyContent: "center",
  },
  link: {
    marginRight: 15,
    color: "#2C0F2C",
    marginLeft: 15,
    "&:hover": {
      cursor: "pointer",
    },
    fontSize: "1rem",
  },
  list: {
    backgroundColor: "#FFECEF",
    textAlign: "center",
  },
  drawerIcon: {
    color: "#CB7A94",
  },
});

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AppBar color="primary.light" position="fixed">
      <Container>
        <Toolbar className={classes.toolbar}>
          <Link
            edge="start"
            activeClass="active"
            to="Gia"
            spy={true}
            smooth={true}
            offset={-70}
            style={{
              fontFamily: "Abril Fatface",
              color: "#CB7A94",
              fontSize: "1.5rem",
            }}
            duration={500}
            className={classes.link}
          >
            giden
          </Link>
          {isSmall ? (
            <div>
              <IconButton onClick={() => setDrawerOpen(true)}>
                <MoreHorizIcon className={classes.drawerIcon} />
              </IconButton>
              <Drawer
                anchor="top"
                className={classes.drawer}
                onBackdropClick={() => setDrawerOpen(false)}
                open={drawerOpen}
              >
                <List className={classes.list}>
                  <ListItem>
                    <Link
                      activeClass="active"
                      to="sobre"
                      spy={true}
                      onClick={() => setDrawerOpen(false)}
                      smooth={true}
                      offset={-70}
                      style={{ fontFamily: "Manrope", color: "#84505B" }}
                      duration={500}
                      className={classes.link}
                    >
                      Quem somos
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      activeClass="active"
                      to="valores"
                      spy={true}
                      onClick={() => setDrawerOpen(false)}
                      smooth={true}
                      style={{ fontFamily: "Manrope", color: "#84505B" }}
                      offset={-70}
                      duration={500}
                      className={classes.link}
                    >
                      O que acreditamos
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      activeClass="active"
                      to="servicos"
                      spy={true}
                      onClick={() => setDrawerOpen(false)}
                      smooth={true}
                      offset={-70}
                      style={{ fontFamily: "Manrope", color: "#84505B" }}
                      duration={500}
                      className={classes.link}
                    >
                      O que oferecemos
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      activeClass="active"
                      to="como-funciona"
                      spy={true}
                      onClick={() => setDrawerOpen(false)}
                      smooth={true}
                      offset={-70}
                      style={{ fontFamily: "Manrope", color: "#84505B" }}
                      duration={500}
                      className={classes.link}
                    >
                      Como funciona
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      activeClass="active"
                      to="cadastro"
                      spy={true}
                      smooth={true}
                      onClick={() => setDrawerOpen(false)}
                      offset={-70}
                      style={{ fontFamily: "Manrope", color: "#84505B" }}
                      duration={500}
                      className={classes.link}
                    >
                      Faça parte
                    </Link>
                  </ListItem>
                </List>
              </Drawer>
            </div>
          ) : (
            <div className={classes.group}>
              <Link
                activeClass="active"
                to="sobre"
                spy={true}
                smooth={true}
                offset={-70}
                style={{ fontFamily: "Manrope", color: "#84505B" }}
                duration={500}
                className={classes.link}
              >
                Quem somos
              </Link>
              <Link
                activeClass="active"
                to="valores"
                spy={true}
                smooth={true}
                style={{ fontFamily: "Manrope", color: "#84505B" }}
                offset={-70}
                duration={500}
                className={classes.link}
              >
                O que acreditamos
              </Link>
              <Link
                activeClass="active"
                to="servicos"
                spy={true}
                smooth={true}
                offset={-70}
                style={{ fontFamily: "Manrope", color: "#84505B" }}
                duration={500}
                className={classes.link}
              >
                O que oferecemos
              </Link>
              <Link
                activeClass="active"
                to="como-funciona"
                spy={true}
                smooth={true}
                offset={-70}
                style={{ fontFamily: "Manrope", color: "#84505B" }}
                duration={500}
                className={classes.link}
              >
                Como funciona
              </Link>
              <Link
                activeClass="active"
                to="cadastro"
                spy={true}
                smooth={true}
                offset={-70}
                style={{
                  fontFamily: "Manrope",
                  color: "#fff",
                  backgroundColor: "#CB7A94",
                  paddingTop: "0.7rem",
                  paddingBottom: "0.7rem",
                  paddingRight: "1.4rem",
                  paddingLeft: "1.4rem",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
                duration={500}
                className={classes.link}
              >
                Faça parte
              </Link>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
