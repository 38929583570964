import React from "react";
import { Element } from "react-scroll";
//import Fade from "react-reveal/Fade";
import imgTwo from "../assets/imgTwo.svg";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFECEF",
  },

  icon: {
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "90%",
  },
  text: {
    color: "#FFECEF",
  },
  buttonMore: {
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none",
    color: "#84505B",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "1rem",
  },
  wrapperSmall: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  },
  imageWrapperSmall: {
    padding: "1.5rem",
  },
  textWrapper: {
    paddingRight: "3rem",
    paddingLeft: "3rem",
    marginTop: "4rem",
    marginBottom: "4rem",
    color: "#84505B",
    alignItems: "center",
  },
});

const QuemSomos = () => {
  const classes = useStyles();

  return (
    <Element id="servicos">
      <div className={classes.root}>
        <Container>
          <Grid container className={classes.wrapper}>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
                <img className={classes.image} src={imgTwo} alt="imgTwo" />
            </Grid>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#84505B",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  O que oferecemos
                </Typography>
              <Typography paragraph variant="h6">
                A giden está desenvolvendo a Gia, a Inteligência Artificial que
                mentora e acompanha sua jornada como empreendedora.
              </Typography>
              <Typography paragraph variant="h6">
                Com a Gia, você descobre em qual fase da jornada está e quais são
                os próximos passos para continuar evoluindo seu negócio.
              </Typography>
              <Typography paragraph variant="h6">
                A Gia aprende suas necessidades e traz conteúdos e indicações
                relevantes para seu crescimento profissional.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default QuemSomos;
