import React from "react";
import { Element } from "react-scroll";
//import Roll from "react-reveal/Roll";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import ucs from "../assets/ucs.svg";
import pegn from "../assets/pegn.svg";
import distrito from "../assets/distrito.svg";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#CB7A94",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    textAlign: "left",
    paddingTop: "0rem",
    paddingBottom: "2rem",
    color: "#FEECEF",
    fontWeight: "normal",
  },
    wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: "5rem",
    color: "#FEECEF",
    fontWeight: "normal",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
    paddingTop: "5rem",
  },
    box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "8rem",
    width: "8rem",
    padding: "1rem",
    margin: "1rem",
    fontWeight: "bold",
  },
    image: {
    width: "90%",
  },
});

const Midia = () => {
  const classes = useStyles();

  return (
    <Element id="midia">
 <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={12} className={classes.titleWrapper}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#FEECEF",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  giden na mídia
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.textWrapper}>
                 <Typography> Acompanhe a giden na internet e em eventos.</Typography>
            </Grid>
            <Grid container xs={12} className={classes.wrapper}>
              <div>
                <Link href="https://revistapegn.globo.com/Empreendedorismo/noticia/2020/11/hackathon-online-da-getnet-premia-solucoes-financeiras-que-apoiam-pequenas-empresas.html" target="_blank">
                 <img className={classes.image} src={pegn} alt="giden no programa pequenas empresas grandes negocios" />
                </Link>
              </div>
              <div>
                <Link href="https://www.youtube.com/watch?v=T-Cdf0V0r0c" target="_blank">
                 <img className={classes.image} src={distrito} alt="giden na competição universitária avante do distrito startups" />
                </Link>
              </div>
              <div>
                <Link href="https://www.youtube.com/watch?v=FfUGdkjwFWM" target="_blank">
                 <img className={classes.image} src={ucs} alt="giden na live mulheres empreendedoras da universidade de caxias do sul" />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default Midia;
