import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';


import { useFormik } from 'formik';

import { withStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import axios from "axios";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  

export default function FormDialog() {
  //variavel e metodo de controle do dialogo
  const [open, setOpen] = React.useState(false);

  //abre dialogo
  const handleClickOpen = () => {
    setOpen(true);
  };

  //fecha dialogo
  const handleClose = () => {
    
    //limpa os campos
    formik.values.firstName = '';
    formik.values.lastName = '';
    formik.values.email = '';
    formik.values.phone = '';
    formik.values.message = '';
    formik.values.question_one = '';
    formik.values.question_two = '';

    //fecha o dialogo
    setOpen(false);
  };

  //variavel de controle do formulario
  const formik = useFormik({
    //parametro inicial de preenchimento dos campos
    initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message:'',
        question_one: '',
        question_two: ''
    },
    //metodo que recebe os dados quando o formulário é submetido
    onSubmit: values => {
        
        //alert(JSON.stringify(values, null, 2));

        const baseURL = "https://api.giden.com.br/invite";

        //conectar por axios na api e enviar os dados e tratar a resposta.
        axios.post(baseURL, {
            first_name: values.firstName,
            last_name: values.lastName,
            email_address: values.email,
            fone_number: values.phone,
            question_one: values.question_one,
            question_two: values.question_two,
            message_additional: values.message
        })
        .then((response) => {
            alert('Tudo certo!. Em breve você receberá um retorno.');
        })
        .catch((err) => {
            alert('Ops!. Ocorreu algum erro ao tentar enviar os dados para o servidor. Por favor, tente novamente mais tarde.');
            console.error(err);
        });

      handleClose();
    },
  });

  

  return (
    <div>
        <Button variant="contained" onClick={handleClickOpen}>
            <Typography color="secondary" align="center" variant="button" display="block">Faça parte</Typography>
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle onClose={handleClose}>
                    <Typography>Faça parte</Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id="firstName"
                        label="Seu nome"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                    />
                    <TextField
                        margin="dense"
                        id="lastName"
                        label="Seu sobrenome"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Seu e-mail"
                        type="email"
                        fullWidth
                        variant="standard"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        label="Seu número de telefone celular"
                        type="phone"
                        fullWidth
                        variant="standard"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                    />
                    
                    <br/><br/>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Qual seu interesse na giden?</FormLabel>
                        <RadioGroup
                        name="question_one"
                        variant="standard"
                        onChange={formik.handleChange}
                        value={formik.values.question_one}
                        >
                        <FormControlLabel size="small" value="Quero utilizar a plataforma da giden" control={<Radio />} label="Quero utilizar a plataforma da giden" />
                        <FormControlLabel size="small" value="Quero fazer negócios com a giden" control={<Radio />} label="Quero fazer negócios com a giden" />
                        <FormControlLabel size="small" value="Quero trabalhar na giden" control={<Radio />} label="Quero trabalhar na giden" />

                        </RadioGroup>
                    </FormControl>

                    <TextField
                        margin="dense"
                        id="message"
                        label="Mensagem"
                        multiline={true}
                        type="text"
                        fullWidth
                        variant="standard"
                        name="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                    />
                    
                    <br/><br/>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Você aceitaria receber novidades e noticiais sobre a giden e nossas parcerias?</FormLabel>
                        <RadioGroup
                        name="question_two"
                        variant="standard"
                        onChange={formik.handleChange}
                        value={formik.values.question_two}
                        >
                        <FormControlLabel size="small" value="Sim, manda tudo!" control={<Radio />} label="Sim, manda tudo!" />
                        <FormControlLabel size="small" value="Não, só quero a resposta da minha mensagem" control={<Radio />} label="Não, só quero a resposta da minha mensagem" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" size="medium">Enviar</Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>
  );
}