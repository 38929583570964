import React from "react";
import { Element } from "react-scroll";
//import Fade from "react-reveal/Fade";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFECEF",
  },

  icon: {
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "90%",
  },
  text: {
    color: "#FFECEF",
  },
  buttonMore: {
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none",
    color: "#84505B",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "1rem",
  },
  wrapperSmall: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  },
  imageWrapperSmall: {
    padding: "1.5rem",
  },
  textWrapper: {
    paddingRight: "3rem",
    paddingLeft: "3rem",
    marginTop: "4rem",
    marginBottom: "4rem",
    color: "#84505B",
    alignItems: "center",
  },
});

const QuemSomos = () => {
  const classes = useStyles();

  return (
    <Element id="sobre">
      <div className={classes.root}>
        <Container>
          <Grid container className={classes.wrapper}>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#84505B",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  Quem somos
                </Typography>
              <Typography paragraph variant="h6">
                A giden é uma iniciativa{" "}
                <span style={{ fontWeight: "bold" }}>
                  criada por e para mulheres
                </span>{" "}
                que desejam empreender.
              </Typography>
              <Typography paragraph variant="h6">
                Sabemos como pode ser difÍcil iniciar o próprio negócio e fazer
                sua empresa crescer, ainda mais enfrentando estigmas sociais
                relacionados a ser mulher.
              </Typography>
              <Typography paragraph variant="h6">
                Por isso, nosso maior objetivo é fortalecer o cenário do
                empreendedorismo feminino no Brasil. Queremos deixar claro que
                empreender é, também, coisa de mulher!
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
              <Link href="https://educa.ibge.gov.br/jovens/conheca-o-brasil/populacao/18320-quantidade-de-homens-e-mulheres.html" target="_blank">

                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", lineHeight: "2.5rem" }}
                >
                  As mulheres representam 51,8% da população brasileira...
                </Typography>
                
                </Link>
                <Typography variant="subtitle1" style={{ color: "black" }}>
                  IBGE 2019
                </Typography>
                <Link href="https://agenciabrasil.ebc.com.br/economia/noticia/2022-03/sebrae-mulheres-lideram-101-milhoes-de-empreendimentos-no-brasil" target="_blank">
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    lineHeight: "2.5rem",
                    color: "#3671A9",
                    marginTop: "1rem",
                  }}
                >
                  mas mulheres donas de negócio representam apenas 34% dos
                  empreendimentos no país
                </Typography>
                </Link>
                <Typography variant="subtitle1" style={{ color: "black" }}>
                  Sebrae 2019
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: "#3671A9",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      color: "white",
                      fontFamily: "Abril Fatface",
                      transform: "rotate(-25deg)",
                    }}
                    variant="h5"
                  >
                    POR QUÊ!?
                  </Typography>
                </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default QuemSomos;
