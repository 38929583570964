import React from "react";
import { Element } from "react-scroll";
import imgOne from "../assets/start_gia_quiz.png";
//import Fade from "react-reveal/Fade";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FEECEF",
    height: "520px",
  },
  icon: {
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "6rem",
    paddingBottom: "4rem",
    alignItems: "center",
  },
  image: {
    width: "90%",
  },
  text: {
    color: "#84505B",
  },
  buttonMore: {
    marginTop:"18%",
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#cb7a94",
    borderColor: "#cb7a94",
    fontWeight: "bold",
    fontSize: "1rem",
    minWidth: "90%",
    display: "inline-block",
    lineHeight: "1.5",
    textAlign: "center",
    textDecoration: "none",
    verticalAlign: "middle",
    cursor: "pointer",
    MozUserSelect: "none",
    userSelect: "none",
    border: "1px solid transparent",
    transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    textDecoration: "none"
  },
  wrapperSmall: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    marginTop: "10rem",
  },
  imageWrapperSmall: {
    backgroundImage: `url(../assets/start_gia_quiz.png)`,
    backgroundSize: "90%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
});

const Gia = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Element id="Gia">
      <div className={classes.root}>
        {isSmall ? (
          <Grid>
            <Grid item xs={12} className={classes.wrapperSmall}>
              <Typography
                className={classes.text}
                color="primary.light"
                variant="h5"
              >
                Olá, amiga!
              </Typography>
              <Typography
                className={classes.text}
                color="primary.light"
                variant="h2"
                style={{ fontFamily: "Abril Fatface" }}
              >
                Descubra a próxima etapa da sua jornada
              </Typography>
              <Typography
                className={classes.text}
                color="primary.light"
                variant="h6"
              >
                Responda o quiz e descubra seu perfil de empreendedora.
              </Typography>
              <Link href="https://gia.giden.com.br/quiz.html" target="_blank" className={classes.buttonMore}>
                Iniciar quiz &gt;
              </Link>
            </Grid> 
          </Grid>
        ) : (
          <Container>
            <Grid container className={classes.wrapper}>
                <Grid item sm={6} style={{
                      marginTop: "0rem",
                    }}>
                  <Typography
                    className={classes.text}
                    color="primary.light"
                    variant="h5"
                  >
                    Olá, amiga!
                  </Typography>
                  <Typography
                    className={classes.text}
                    color="primary.light"
                    variant="h2"
                    style={{ fontFamily: "Abril Fatface" }}
                  >
                   Descubra a próxima etapa da sua jornada
                  </Typography>
                  <Typography
                    className={classes.text}
                    color="primary.light"
                    variant="h6"
                  >
                    Responda o quiz e descubra seu perfil de empreendedora.
                  </Typography>
                </Grid>
                <Grid className={classes.imageWrapperSmall} item sm={6}>
                  <Grid style={{ minHeight: "300px"}}>
                    <Link href="https://gia.giden.com.br/quiz.html" className={classes.buttonMore} style={{textDecoration: "none"}}>
                      Iniciar quiz &gt;
                    </Link>
                  </Grid>
                </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Element>
  );
};

export default Gia;
