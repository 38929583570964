import React from "react";
import { Element } from "react-scroll";
//import Roll from "react-reveal/Roll";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#84505B",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    color: "#FEECEF",
    fontWeight: "normal",
  },
});

const QuemSomos = () => {
  const classes = useStyles();

  return (
    <Element id="valores">
      <div className={classes.root}>
        <Container>
          <Grid container className={classes.textWrapper}>
            <Grid item xs={12} sm={9} md={6}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#FEECEF",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  O que acreditamos
                </Typography>
              <Typography
                paragraph
                variant="h5"
                style={{ marginBottom: "2rem", marginTop: "3rem" }}
              >
                Acreditamos que empreender não precisa ser um processo chato e
                complicado.
              </Typography>
              <Typography paragraph variant="h5">
                Queremos que mais mulheres possam comemorar sua independência
                financeira realizando o sonho de empreender, de forma{" "}
                <span style={{ fontWeight: "bolder" }}>
                  organizada, virtual e divertida.
                </span>
              </Typography>
            </Grid>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                width: "100%",
              }}
            >
                <Typography
                  style={{
                    backgroundColor: "#CB7A94",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    color: "white",
                    fontFamily: "Abril Fatface",
                    transform: "rotate(-25deg)",
                  }}
                  variant="h5"
                >
                  AGORA SIM!
                </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default QuemSomos;
