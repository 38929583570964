import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FFECEF",
    width: "100%",
    paddingTop: "3rem",
  },
  footer: {
    backgroundColor: "#84505B",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "#FFECEF",
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  link: {
    color: "#FFECEF",
    fontSize: "2rem",
  },
  footerWrapperCenter: {
    color: "#FFECEF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  }
});

const Layout = ({ children }) => {
  const classes = useStyles();

  // const scrollToTop = () => {
  // 	scroll.scrollToTop();
  // };
  return (
    <div className={classes.root}>
      <Navbar />
      {children}
      <div className={classes.footer}>
        <Container className={classes.footerWrapper}>
          <Link href="mailto:gia@giden.com.br" target="_blank">
            <Typography variant="h6" style={{color: "#fff"}}> gia@giden.com.br </Typography>
          </Link>
          <div>
            <Link href="https://instagram.com/gidenoficial" target="_blank">
              <InstagramIcon className={classes.link} />
            </Link>
            <Link href="https://www.facebook.com/gidenoficial" target="_blank">
              <FacebookIcon className={classes.link} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/giden-oficial"
              target="_blank"
            >
              <LinkedInIcon className={classes.link} />
            </Link>
          </div>
        </Container>
        <Container className={classes.footerWrapperCenter}>
          <Typography variant="h6"> giden &copy; 2023. Todos os direitos reservados.</Typography>
        </Container>
      </div>
    </div>
  );
};

export default Layout;
