import React from "react";

import Intro from "./sections/Intro";
import Gia from "./sections/Gia";
import Servicos from "./sections/Servicos";
import QuemSomos from "./sections/QuemSomos";
import Valores from "./sections/Valores";
import Midia from "./sections/Midia";
import ComoFunciona from "./sections/ComoFunciona";
import Cadastro from "./sections/Cadastro";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Layout from "./components/Layout";
import "@fontsource/abril-fatface";
import "@fontsource/manrope";
import ReactGA from "react-ga";

const trackingId = "UA-193320285-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const theme = createMuiTheme({
  typography: {
    fontFamily: "Manrope",
  },
  palette: {
    primary: {
      main: "#CB7A94",
      light: "#FFECEF",
    },
    secondary: {
      main: "#84505B",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Gia />
        <Intro />
        <QuemSomos />
        <Valores />
        <Servicos />
        <ComoFunciona />
        <Cadastro />
        <Midia />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
