import React from "react";
import { Element } from "react-scroll";
//import Zoom from "react-reveal/Zoom";
//import Fade from "react-reveal/Fade";
import imgTres from "../assets/imgTres.svg";
//MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

//import Button from '@material-ui/core/Button';
import FormDialog from "../components/FormDialog.js"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FEECEF",
  },

  icon: {
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "90%",
  },
  text: {
    color: "#CB7A94",
  },
  buttonMore: {
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none",
    color: "#84505B",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "1rem",
  },
  wrapperSmall: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
  },
  imageWrapperSmall: {
    padding: "1.5rem",
  },
  textWrapper: {
    paddingRight: "3rem",
    paddingLeft: "3rem",
    marginTop: "4rem",
    marginBottom: "4rem",
    color: "#CB7A94",
    alignItems: "center",
  },
  Button: {
    marginRight: 15,
    color: "#2C0F2C",
    marginLeft: 15,
    "&:hover": {
      cursor: "pointer",
    },
    fontSize: "1rem",
  },
});

const Cadastro = () => {
  const classes = useStyles();

  return (
    <Element id="cadastro">
      <div className={classes.root}>
        <Container>
          <Grid container className={classes.wrapper}>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
                <Typography
                  className={classes.text}
                  variant="h3"
                  style={{
                    fontFamily: "Abril Fatface",
                    color: "#CB7A94",
                    marginBottom: "2rem",
                  }}
                  paragraph
                >
                  Faça parte
                </Typography>
              <Typography paragraph variant="h6">
                Assim como muitas empreendedoras que pretendemos ajudar, a giden
                está no inicio da jornada, desenvolvendo o produto.
              </Typography>
              <Typography paragraph variant="h6">
                Se você quer participar dessa jornada e nos ajudar a construir a
                giden, ou simplesmente ficar por dentro de novidades e
                lançamentos, deixe seu contato na nossa lista vip de pessoas que
                mudarão mercado de trabalho.
              </Typography>
              <FormDialog/>
            </Grid>
            <Grid item md={6} xs={12} className={classes.textWrapper}>
                <img className={classes.image} src={imgTres} alt="imgTres" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Element>
  );
};

export default Cadastro;
